<template>
  <!--  <div class="row">-->
  <!--    <div class="col-sm-3">-->
    <h5 style="text-align: left; ">
        <MDBBtn
                @click="collapse = !collapse"
                aria-controls="announceCollapse"
                :aria-expanded="collapse"
                tag="a" color="secondary">
            <MDBIcon :icon="collapse ? `arrow-down` : `arrow-up`"/>
        </MDBBtn>&#9;News & Events&#9;<span class="badge" v-if="announcements.length > 0" style="border-radius: 10px;">{{
        announcements.length
        }}</span></h5>
  <!--    </div>-->

  <!--  </div>-->
    <div class="d-flex justify-content-start">
        <MDBCollapse
                id="announceCollapse"
                v-model="collapse">
            <div class="container"
                 style="margin-top: 1%; padding-top: 1%; border: 1px solid black; margin-bottom: 1%; padding-bottom: 1%;overflow-y: scroll; max-width: 235px">


                <div v-for="(item, index) in announcements" :key="index" style="margin-bottom: 2%;">
                    <!--        <div class="card-header">-->
                    <button @click="showModalClicked(item)" class="btn-info button">{{
                        item.announcementTitle
                        }}
                    </button>
                    <!--        </div>-->
                </div>
            </div>
        </MDBCollapse>
    </div>
    <div v-if="showModal">
        <Teleport to="body">
            <AnnouncementModal :announcement-data="selectedAnnouncement" :show="showModal" @close="showModal = false"/>
        </Teleport>
    </div>
</template>

<script>
import store from "@/store";
import {ref, onBeforeMount} from "vue";
import axios from "axios";
import {MDBCollapse, MDBBtn, MDBIcon} from "mdb-vue-ui-kit";
import {getActiveAnnouncements} from "@/Helpers/ApiCalls/AnnouncementAPICalls";
import AnnouncementComponent from "@/components/Announcements/AnnouncementComponent.vue";
import AnnouncementModal from "@/components/Announcements/AnnouncementModal.vue";

export default {
	name: "AnnouncementList",
	components: {AnnouncementModal, MDBCollapse, MDBBtn, MDBIcon},
	setup() {
		const authToken = store.getters['auth/authenticated'];
		const announcements = ref([]);
		const selectedAnnouncement = ref({});
		const collapse = ref(false);
		const showModal = ref(false);

		onBeforeMount(() => {
			getAnnouncements();
		})

		async function getAnnouncements() {
			await axios.get(getActiveAnnouncements(), {
				headers: {
					"Authorization": `Bearer ${authToken.value}`
				}
			}).then((result) => {
				announcements.value = result.data;
			})
		}

		function showModalClicked(event) {
			showModal.value = true;
			selectedAnnouncement.value = event;
		}

		return {
			announcements,
			selectedAnnouncement,
			showModal,
			collapse,
			showModalClicked
		}
	}
}
</script>

<style scoped>

</style>