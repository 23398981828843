<template>

  <h5 style="text-align: center; color: black;">
    <MDBBtn
      @click="collapse = !collapse"
      aria-controls="requestCollapse"
      :aria-expanded="collapse"
      tag="a"
      color="secondary"
    >
      <MDBIcon :icon="collapse ? `arrow-down` : `arrow-up`"></MDBIcon>
    </MDBBtn>&#9;Pending Requests
    <b-tooltip target="_self" title="Sort by registration date">
      <button @click="reverseSort" :class="reversed ? 'bi bi-sort-down' : 'bi bi-sort-up'"
              alt="Sort pending requests by registration date"></button>
    </b-tooltip>
    <b-tooltip target="_self" title="Count of pending users">
      <span class="badge" v-if="pendingUsers.length > 0" style="border-radius: 10px;">{{ pendingUsers.length }}</span>
    </b-tooltip>
  </h5>

  <MDBCollapse
      id="requestCollapse"
      v-model="collapse">
    <div
         style="overflow-x: scroll; border: 1px solid black;">
      <div v-if="!isLoading">
        <div v-if="pendingUsers.length > 0" class="container" style="height: 250px; overflow-y: scroll;">
          <div v-for="user in pendingUsers" :key="user" style="max-width: 600px; max-height: 250px; margin-bottom: 2%">
            <PendingRequest :user-data="user" @refreshPendingUsers="getPendingUsers" />
          </div>
        </div>
        <div v-else class="container flex-nowrap">
          <h6>No user registration requests are currently pending.</h6>
        </div>
      </div>
      <div v-else>
        <Loader :height="'70px;'" :width="'70px;'"/>
      </div>
    </div>
  </MDBCollapse>
</template>

<script>
import {useStore} from "vuex";
import {onBeforeMount, ref} from "vue";
import axios from "axios";
import {MDBCollapse, MDBIcon, MDBBtn} from "mdb-vue-ui-kit";
import {API_URL} from "../../../Constants";
import PendingRequest from "@/components/PendingNotification/PendingRequest";
import Loader from "@/components/Loader";

export default {
  name: "PendingRequestsWrapper",
  components: {
    Loader,
    PendingRequest,
    MDBCollapse, MDBIcon, MDBBtn
  },
  setup() {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const pendingUsers = ref([]);
    const reversed = ref(true);
    const isLoading = ref(false);
    const collapse = ref(false);

    onBeforeMount(() => {
      getPendingUsers();
    })

    async function getPendingUsers() {
      isLoading.value = true;
      let get_uri = API_URL + "/user/pendingScoped";
      await axios.get(get_uri, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        // pendingUsers.value = result.data.sort((a, b) => {
        //   console.log(`a: ${a.registrationDate} ${typeof a.registrationDate} \nb: ${b.registrationDate} ${typeof b.registrationDate}`);
        //   a.registrationDate > b.registrationDate ? 1 : -1
        // });
        pendingUsers.value = result.data.sort((a, b) => a.registrationDate > b.registrationDate ? 1 : -1);
      }).finally(() => {
        if (reversed.value) pendingUsers.value.reverse();
        isLoading.value = false;
      })
    }

    function showLoader(value) {
      isLoading.value = value;
    }

    function reverseSort() {
      pendingUsers.value.reverse();
      reversed.value = !reversed.value;
    }

    return {
      pendingUsers,
      reversed,
      isLoading,
      collapse,
      showLoader,
      getPendingUsers,
      reverseSort
    }
  }
}
</script>

<style scoped>

</style>