<template>
  <div class="col-sm">
    <b-tooltip target="_self" title="Approve this user">
      <button class="bi bi-hand-thumbs-up-fill btn-primary rounded-circle" @click="selectIcon($event, 'A')" alt="approve" style="border-radius: 100%; height: 34px; width: 34px;"></button>
    </b-tooltip>
  </div>
  <div class="col-sm">
    <b-tooltip target="_self" title="Remove this user">
      <button class="bi bi-hand-thumbs-down-fill btn-danger rounded-circle" @click="selectIcon($event, 'R')" alt="remove" style="border-radius: 100%;  height: 34px; width: 34px;"></button>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: "PendingStatusInteract",
  setup(props, context) {
    function selectIcon(event, value) {
      context.emit('approvalEvent', value);
    }

    return {
      selectIcon
    }
  }
}
</script>

<style scoped>
.col-sm {
  margin-left: 2px;
  margin-right: 2px;
}

button {
  padding: 5px;
}
.btn-primary {
  color: #fff;
}
.btn-primary:hover {
  color: #000
}
</style>