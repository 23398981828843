<template>

    <div v-for="user in sortedUsers" :key="user.idUser">
      <UserInfo
          :user="user"
          :show-edit-route="false"
          :show-view-route="true"
      />
    </div>
</template>

<script>
import UserInfo from "@/components/UserOrganizationHierarchy/UserInfo";
import {computed} from "vue";

export default {
  name: "UserGroupDropdownDisplay",
  components: {UserInfo},
  props: {
    groupData: Object
  },
  setup(props) {
    const sortedUsers = computed(() => {
      let tmp = props.groupData;
      return tmp.groupedUsers.sort((a, b) => a.lastName > b.lastName ? 1 : -1);
    })

    return {
      sortedUsers
    }
  }
}
</script>

<style scoped>

</style>