<template>
  <div>
    <h1 style="opacity: 100%; color: black;">Dashboard</h1>
    <hr style="opacity: 100%; color: black;"/>
  </div>
  <div class="container row" style="display: inline-flex; justify-content: space-between; align-content: flex-start">
    <div class="col" style="align-content: flex-start; justify-content: flex-start; text-align: left">
      <AnnouncementList/>
    </div>
    <div class="col" v-if="authUser.position.roleHierarchy !== 6 && authUser.position.roleHierarchy !== 5">
      <PendingRequestsWrapper/>
    </div>
    <div class="col justify-content-end"
         v-if="authUser.position.roleHierarchy !== 6 && authUser.position.roleHierarchy !== 5">
      <UserGroupDisplayWrapper
      />
    </div>
  </div>


  <div class="album" style="margin-top: 50px; display: inline-flex">
    <div class="card"
         style="margin-left: 0;" v-if="canManageOrgs">
      <div class="card-header">
        <span style="color: black; opacity: 100%">Organization Management</span>
      </div>
      <div class="card-body" v-if="canCreateNebraskaOrg">
        <router-link :to="{name: 'CreateDistrict'}">Create a Nebraska School District</router-link>
      </div>
      <div class="card-body" v-if="canCreateNebraskaOrg">
        <router-link :to="{name: 'CreateSchool'}">Create a Nebraska School</router-link>
      </div>
      <div class="card-body">
        <router-link v-if="authUser.inNebraska" :to="{name: 'OutstateCreate'}">Create an Outstate Organization
        </router-link>
        <router-link v-else-if="user.countryCode === 'US'" :to="{name: 'OutstateCreate'}">Create
          {{ outstateArticle }}
          {{ user.stateName }}
          Organization
        </router-link>
        <router-link v-else :to="{name: 'OutstateCreate'}">Create {{ outstateArticle }} {{ user.countryName }}
          Organization
        </router-link>
      </div>
      <!--            <div class="card-body">-->
      <!--                <router-link :to="{name: 'OrganizationHierarchyAdminView'}">Organization/User Hierarchy</router-link>-->
      <!--            </div>-->
      <div class="card-body" v-if="authUser.position.roleHierarchy === 1">
        <router-link :to="{name: 'OrganizationMerge'}">Merge Organizations</router-link>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <span style="color: black; opacity: 100%">User Management</span>
      </div>
      <div class="card-body">
        <router-link :to="{name: 'ViewUser', params: {idUser: encodedUserId}}">My Profile</router-link>
      </div>
      <div class="card-body"
           v-if="authUser.position.roleHierarchy !== 6 && authUser.position.roleHierarchy !== 5">
        <router-link :to="{ name: 'AddUser' }">Add User</router-link>
      </div>
      <div class="card-body"
           v-if="authUser.position.roleHierarchy !== 6 && authUser.position.roleHierarchy !== 5">
        <router-link :to="{ name: 'UserSearch'}">User Management</router-link>
      </div>
      <div v-if="authUser.position.roleHierarchy === 1" class="card-body">
        <router-link :to="{name: 'RemovedUsers'}">Removed Users</router-link>
      </div>
<!--      <div v-if="authUser.position.roleHierarchy === 1" class="card-body">-->
<!--        <router-link :to="{name: 'UserSearch'}">Search Users</router-link>-->
<!--      </div>-->
    </div>
    <div class="card" v-if="showHyperlinks">
      <div class="card-header">
        <span style="color: black; opacity: 100%">Canvas Modules</span>
      </div>
      <div v-if="authUser.position.roleHierarchy === 5">

        <div class="card-body">
          <p>
            <a href="https://esucc.instructure.com/courses/261/pages/title-i">Module 1: Title I</a>
          </p>
        </div>
        <div class="card-body">
          <p>
            <a href="https://esucc.instructure.com/courses/261/pages/special-education-requirements">Module
              2: Special
              Education Requirements</a>
          </p>
        </div>
        <div class="card-body">
          <p>
            <a href="https://esucc.instructure.com/courses/261/pages/early-child-requirements">Module 3:
              Early
              Childhood</a>
          </p>
        </div>
      </div>
      <div v-else>
        <div class="card-body">
          <p>
            <a href="https://esucc.instructure.com/courses/268">Supervisor Course</a>
          </p>
        </div>
      </div>
      <div class="card-body">
        <p>
          <router-link :to="{name: 'CertificationReport', params: {idUser: encodedUserId}}">My Certifications
          </router-link>
        </p>
      </div>
    </div>

    <div class="card" v-if="!isLoading">
      <div class="card-header d-flex">
        <span style="color: black; opacity: 100%">Your Educational Organization(s)</span>
      </div>

      <div v-if="authUser.inNebraska">
        <div class="card-body">
          <router-link :to="{name: 'ViewEsu', params: {idEsu: parseInt(user.idEsu)}}">{{
              user.esuName
            }}
          </router-link>
        </div>
        <div v-if="displayDistrict" class="card-body">
          <router-link :to="{name: 'ViewDistrict', params: {idDistrict: parseInt(user.idDistrict)}}">
            {{ user.districtName }}
          </router-link>
        </div>
        <div v-if="displaySchool" class="card-body">
          <router-link :to="{name: 'ViewSchool', params: {idSchool: parseInt(user.idSchool)}}">
            {{ user.schoolName }}
          </router-link>
        </div>
      </div>
      <div v-else>
        <div class="card-body">
          <p>{{ user.countryName }}</p>
        </div>
        <div class="card-body" v-if="user.countryCode === 'US'">
          <p>{{ user.stateName }}</p>
        </div>
        <div class="card-body">
          <br/>
          <router-link
              :to="{name: 'ViewOutstate', params: {idOrganization: parseInt(user.idOutstateOrganization)}}">
            {{ user.outStateOrgName }}
          </router-link>
        </div>
      </div>

      <div class="card-body">
        <router-link :to="{name: 'OrganizationList'}">View All Organizations</router-link>
      </div>
    </div>
    <!--    reports -->
    <div class="card" v-if="showSiteGrowthReport">
      <div class="card-header">
        <span style="color: black; opacity: 100%;">Reports</span>
      </div>
      <div class="card-body">
        <router-link :to="{name: 'ReportDashboard'}">Reports</router-link>
      </div>
      <div class="card-body" v-if="authUser.position.roleHierarchy === 1">
        <router-link :to="{name: 'HistoricalCerts'}">Historical Certifications
        </router-link><b-tooltip target="_self" title="Data from the old Project Para"><i class="bi-info-circle"></i></b-tooltip>
      </div>
      <div class="card-body"></div>
      <div class="card-body"></div>
      <!--          <div class="card-body">-->
      <!--            <router-link :to="{name: 'SiteGrowth'}">Participant Growth</router-link>-->
      <!--          </div>-->

    </div>


    <div class="card" v-if="user.roleHierarchyId === 1 && user.idEsu === 20" style="margin-right: 0;">
      <div class="card-header">
        <span style="color: black; opacity: 100%">System Management
          <b-tooltip target="tooltip-management"
                     title="This card is to manage data in ESUCC static tables and is only visible to ESUCC users">
            {{ infoIcon }}
          </b-tooltip>
          <span></span>
        </span>
      </div>
      <div class="card-body">
        <router-link :to="{name: 'AssignmentManagement'}">Assignment Management</router-link>
      </div>
      <div class="card-body">
        <router-link :to="{name: 'QuizManagement'}">Quiz Management</router-link>
      </div>
      <div class="card-body">
        <router-link :to="{name: 'CreateAnnouncement'}">Create Announcement</router-link>
      </div>
      <div class="card-body">
        <router-link :to="{name: 'AnnouncementDashboard'}">Announcement Management</router-link>
      </div>
    </div>


  </div>
  <div v-if="showChangePasswordModal">
    <Teleport to="body">
      <PasswordResetComponent :user-email="user.email" :show="showChangePasswordModal"
                              :resetting-on-login="true"
                              :admin-changing="false"
                              @close="showChangePasswordModal = false"
                              @retry="retryPasswordReset"
      />
    </Teleport>
  </div>
  <!--  </MDBCollapse>-->
</template>

<script>
import {useStore} from "vuex";
import {computed, onBeforeMount, ref} from "vue";
import {
  isDistrictAdmin,
  isNde,
  isParaEducatorCollegeStudentOrEarlyChildhood,
  isSchoolUser,
  isSuperUser,
  showDistrict,
  showSchool
} from "@/RoleCheck";
import {API_URL} from "../../../Constants";
import axios from "axios";
import PendingRequestsWrapper from "@/components/PendingNotification/PendingRequestsWrapper";
import UserGroupDisplayWrapper from "@/views/SuperUserViews/UserGroups/UserGroupDisplayWrapper";
import AnnouncementList from "@/components/Announcements/AnnouncementList.vue";
import {MDBBtn, MDBCollapse, MDBIcon} from "mdb-vue-ui-kit";
import PasswordResetComponent from "@/components/PasswordReset/PasswordResetComponent.vue";
import Swal from "sweetalert2";

export default {
  name: "AdminDashboard",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    PasswordResetComponent,
    // eslint-disable-next-line vue/no-unused-components
    AnnouncementList, UserGroupDisplayWrapper, PendingRequestsWrapper, MDBCollapse, MDBBtn, MDBIcon
  },
  setup() {
    const store = useStore();
    const authUser = computed(() => store.getters['auth/user']);
    const authToken = computed(() => store.getters['auth/authenticated']);
    const showReports = ref(false);
    const user = ref({});
    const isLoading = ref(true);
    const infoIcon = ref(null);
    const collapse = ref(false);
    const showChangePasswordModal = ref(false);

    onBeforeMount(() => {
      if (authUser.value.usingTemporaryLogin) showChangePasswordModal.value = true;
      getUser();
      isLoading.value = false;
      infoIcon.value = '\u24D8';
    })

    const outstateArticle = computed(() => {
      let vowel_regex = /^\[aieouAEIOU]/;

      if (user.value.stateCode !== null && user.value.stateCode !== undefined && user.value.stateCode !== '') {
        return vowel_regex.test(user.value.stateCode.charAt(0)) ? 'an' : 'a';
      } else if (user.value.countryCode !== null && user.value.countryCode !== undefined && user.value.countryCode !== '') {
        return vowel_regex.test(user.value.countryCode.charAt(0)) ? 'an' : 'a';
      }
      return 'an Outstate';
    })

    const displayDistrict = computed(() => {
      return showDistrict(authUser.value.position.roleHierarchy);
    })

    const displaySchool = computed(() => {
      return showSchool(authUser.value.position.roleHierarchy);
    })

    async function getUser() {
      let get_uri = API_URL + "/user/byId?_idUser=" + parseInt(authUser.value.idUser);
      await axios.get(get_uri, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        user.value = result.data;
      })
    }

    function retryPasswordReset(message) {
      showChangePasswordModal.value = false;
      Swal.fire({
        title: "Something Went Wrong!",
        text: message,
        icon: "error",
        confirmButtonText: "Okay",
      }).then(() => {
        showChangePasswordModal.value = true;
      })
    }

    const showSiteGrowthReport = computed(() => {
      return !isParaEducatorCollegeStudentOrEarlyChildhood(authUser.value.position.roleHierarchy);
    })

    const showDistrictGrowthReport = computed(() => {
      return isDistrictAdmin(authUser.value.position.roleHierarchy);
    })

    const encodedUserId = computed(() => {
      return btoa(user.value.idUser.toString());
    })

    const showHyperlinks = computed(() => {
      return user.value.enrollmentType.enrollmentSelectValue !== "none";
    })

    const canCreateNebraskaOrg = computed(() => {
      return authUser.value.inNebraska && (isSuperUser(authUser.value.position.roleHierarchy) || isNde(authUser.value.position.roleHierarchy))
    })

    const canManageOrgs = computed(() => {
      return (isSuperUser(authUser.value.position.roleHierarchy) || isNde(authUser.value.position.roleHierarchy))
    })

    return {
      authUser,
      displayDistrict,
      displaySchool,
      user,
      showReports,
      showSiteGrowthReport,
      isLoading,
      outstateArticle,
      infoIcon,
      showDistrictGrowthReport,
      encodedUserId,
      showHyperlinks,
      collapse,
      showChangePasswordModal,
      canCreateNebraskaOrg,
      canManageOrgs,
      retryPasswordReset,
    }
  }
}
</script>

<style scoped>
hr {
  opacity: 80%;
  color: black;
}

span {
  opacity: 90%;
  color: black;
}

.card {
  display: flex;
  max-width: 400px;
  /*margin-left: 10px;*/
  margin-right: 10px;
  margin-bottom: 10px;
  max-height: 800px;
  /*height: 250px;*/
  width: 250px;
  border-radius: 0;
  padding: 0;
  border: 1px solid rgb(0, 0, 0);
}

.card-img-top {
  height: 300px;
  width: 190px;
  margin: 5%;
  border: 1px solid rgb(0, 0, 0);
  overflow: hidden;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-header {
  /*padding: 4% 8%;*/
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgb(0, 0, 0);
  height: 75px;
  justify-content: center;
  align-items: center;
  display: flex;
  /*text-overflow: ellipsis;*/
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-text {
  margin-bottom: 0.5rem;
}

.album {
  display: inline-block;
}

</style>
