<template>
  <div class="card" style="display: inline-flex; max-height: 150px; max-width: 600px">
    <div class="row " style=" min-width: 400px; max-width: 400px; padding-left: 5px;">
      <div class="col-8" style="text-align: left; color: black">
        <router-link :to="{name: 'ViewUser', params: {idUser: userRouteId}}">{{ userData.firstName }}
          {{ userData.lastName }}
        </router-link>
        <br/>
        {{ userData.email }}
        <br/>
        <span style="font-weight: bold">Requested: </span><span> {{
          userData.registrationDate !== null ? userData.registrationDate : "03/01/2021"
        }}</span>
        <br/>
        <span style="font-weight: bold">Role: </span> <span>{{ userData.roleName }}</span>
        <br/>
        <span style="font-weight: bold">Course(s): </span><span>{{ userData.requestedEnrollmentType }}</span>
      </div>
      <div class="col-2 align-self-center" style="display: inline-flex; margin-top: auto; margin-bottom: auto; ">
        <PendingStatusInteract
            @approvalEvent="userStatusHelper($event)"
        />
      </div>
      <!--      <div class="col-1"></div>-->
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {API_URL} from "../../../Constants";
import axios from "axios";
import router from "@/router";
import PendingStatusInteract from "@/components/PendingNotification/PendingStatusInteract";
import {computed} from "vue";

export default {
  name: "PendingRequest",
  components: {PendingStatusInteract},
  props: {
    userData: Object
  },
  setup(props, context) {

    function userStatusHelper(event) {
      changeUserStatus(event);
    }

    async function changeUserStatus(statusCode) {
      context.emit('showLoader', true);
      const put_uri = API_URL + "/user/changeStatus";
      await axios.put(put_uri, {
            idUser: props.userData.idUser,
            statusCode: statusCode
          },
          {
            headers: {
              Authorization: "Bearer " + props.authToken
            }
          }).then((result) => {
            if (result.status === 200) {
              context.emit('refreshPendingUsers', true);
              if (statusCode === 'A') {
                Swal.fire({
                  title: 'Successfully Registered',
                  html: `<p>${props.userData.firstName} ${props.userData.lastName} will have 24 hours to create a password for Project Para. </p><p>Please be sure to contact them within 24 hours.</p>`,
                  confirmButtonText: 'Continue',
                  icon: 'success'
                }).then(function () {
                  location.reload();
                })
              } else {
                Swal.fire({
                  title: 'User Registration Denied',
                  html: `<p>${props.userData.firstName} ${props.userData.lastName} was not accepted for registration in Project Para.</p>`,
                  confirmButtonText: 'Continue',
                  icon: 'success'
                }).then(function () {
                  location.reload();
                })
              }
            }
          }
      )/*.finally(() => {
        context.emit('showLoader', false);
      })*/
    }

    const userRouteId = computed(() => {
      return btoa(props.userData.idUser);
    })

    return {
      userRouteId,
      userStatusHelper
    }
  }
}
</script>

<style scoped>
.card {
  border-radius: 0;

  border: 1px solid rgb(0, 0, 0);

}
</style>