<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot class="header">
              <h5 class="modal-title">Change Password</h5>
            </slot>
            <button v-if="!resettingOnLogin" type="button" class="btn-close" data-dismiss="modal"
                    @click="$emit('close')"></button>
          </div>
          <slot name="body">
            <div class="modal-body">
              <hr/>
              <div class="row">
                <div class="col">
                  <form @submit.prevent="submitReset">
                    <div v-if="!adminChanging">
                      <label>{{ resettingOnLogin ? `Temporary Password` : `Old Password` }}</label>
                      <div class="input-group">
                        <input class="form-control" :type="showOldPasswordText ? `text` : `password`"
                               v-model="passwordResetBody.oldPassword" spellcheck="false"/>
                        <div class="input-group-append">
                        <span class="input-group-text" @click="showOldPasswordText = !showOldPasswordText">
                          <i :class="showOldPasswordText ? `bi bi-eye-slash` : `bi bi-eye`"></i>
                        </span>
                        </div>
                      </div>
                    </div>

                    <label>New Password</label>
                    <div class="input-group">
                      <input class="form-control" :type="showNewPasswordText ? `text` : `password` "
                             v-model="passwordResetBody.newPassword"
                             @input="setPasswordMessages" spellcheck="false"/>
                      <div class="input-group-append">
                    <span class="input-group-text" @click="showNewPasswordText = !showNewPasswordText">
                      <i :class="showNewPasswordText ? `bi bi-eye-slash` : `bi bi-eye`"></i>
                    </span>
                      </div>
                    </div>
                    <label>Confirm Password</label>
                    <div class="input-group">
                      <input class="form-control" :type="showNewPasswordText ? `text` : `password` "
                             v-model="passwordResetBody.confirmPassword"
                             @input="setPasswordMessages" spellcheck="false"/>
                      <div class="input-group-append">
                    <span class="input-group-text" @click="showNewPasswordText = !showNewPasswordText">
                      <i :class="showNewPasswordText ? `bi bi-eye-slash` : `bi bi-eye`"></i>
                    </span>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col">
                  <Transition name="slide-fade">
                    <div v-if="showContainsMessage">
                      <div v-for="(value, name, index) in errorMessages" :key="index">
                        <span v-if="value !== ''">{{ value }}</span>
                      </div>
                    </div>
                  </Transition>
                  <br v-if="resettingOnLogin"/>
                  <span v-if="resettingOnLogin" style="font-weight: bold;">
                    You are currently using a temporary password that expires within 24 hours. Please reset now.
                  </span>
                  <br/>
                  <span>Note: You may not reuse your previous 5 passwords.</span>

                </div>
                <div style="align-items: center; justify-content: center; text-align: center">
                  <button class="button btn-primary" @click="submitReset" :disabled="!canSubmit">Reset Password</button>
                </div>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {computed, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
import store from "@/store";
import {loggedInPasswordReset} from "@/Helpers/ApiCalls/AuthenticationAPICalls";
import Swal from "sweetalert2";

export default {
  name: "PasswordResetComponent",
  props: {
    userEmail: {
      type: String,
      required: true,
    },
    adminChanging: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false
    },
    resettingOnLogin: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  setup(props, context) {
    const authToken = store.getters['auth/authenticated'];
    const passwordResetBody = ref({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      email: ""
    });

    const authStore = useStore();

    const showNewPasswordText = ref(false);
    const showOldPasswordText = ref(false);


    const showContainsMessage = ref(false);

    const errorMessages = ref({
      containsMessage: "",
      lengthMessage: "",
      containsUpperMessage: "",
      containsLowerMessage: "",
      containsNumberMessage: "",
      containsSpecialMessage: "",
      passwordsMatchMessage: ""
    });

    function setPasswordMessages() {
      let testing_value = passwordResetBody.value.newPassword;
      let diff = 8 - testing_value.length;
      errorMessages.value.containsMessage = testing_value.length < 8 ?
          `Password must contain at least 8 characters. ${diff} characters remaining.` : ``;

      errorMessages.value.containsUpperMessage = /[A-Z]/.test(testing_value) ? `` :
          `At least one upper case letter.`;

      errorMessages.value.containsLowerMessage = /[a-z]/.test(testing_value) ?
          `` : `At least one lower case letter.`;
      errorMessages.value.containsNumberMessage = /[0-9]/.test(testing_value) ?
          `` : `At least one number.`;

      errorMessages.value.containsSpecialMessage = /[#?!@$%^&*-]/.test(testing_value) ?
          `` : `At least one special character (#?!@$%^&*-).`;
      errorMessages.value.passwordsMatchMessage = passwordResetBody.value.newPassword === passwordResetBody.value.confirmPassword ?
          `` : `Passwords must match`;

      showContainsMessage.value =
          errorMessages.value.lengthMessage !== ''
          || errorMessages.value.containsUpperMessage !== ''
          || errorMessages.value.containsLowerMessage !== ''
          || errorMessages.value.containsNumberMessage !== ''
          || errorMessages.value.containsSpecialMessage !== ''
          || errorMessages.value.passwordsMatchMessage !== '';

      errorMessages.value.containsMessage = showContainsMessage.value ? `You must include the password rules below:` : ``;
    }

    async function submitReset() {
      passwordResetBody.value.email = props.userEmail;

      await axios.post(loggedInPasswordReset(), passwordResetBody.value, {
        headers: {
          "Authorization": `Bearer ${authToken.value}`
        }
      }).then((result) => {
        const status_success = result.status === 200;
        let icon = status_success ? 'success' : 'error';
        let title_string = status_success ? 'Successfully Updated Password!' : 'Something Went Wrong!';
        if (status_success) {
            context.emit('close');
            Swal.fire({
                title: title_string,
                text: result.data.message,
                icon: icon,
                confirmButtonText: 'Okay'
            }).then(() => {
                if (status_success) {
                    let access_body = {
                        token: authToken.value,
                        usedLoginPage: false
                    }
                    store.dispatch('auth/attemptAccess', access_body)
                }
            })
        } else {
            context.emit('retry', result.data.message);
        }
      }).catch((error) => {

        Swal.fire({
          title: "Something Went Wrong!",
          text: error.message,
          icon: "error",
          confirmButtonText: "Okay",
        }).then(() => {
            context.emit('retry');
        })
      })
    }

    const canSubmit = computed(() => {
      return !showContainsMessage.value;
    })


    return {
      passwordResetBody,
      errorMessages,
      canSubmit,
      showContainsMessage,
      showNewPasswordText,
      showOldPasswordText,
      setPasswordMessages,
      submitReset,
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.modal-container {
  max-width: 800px;
  width: 80%;
  height: auto;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}


.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

input {
  border: 1px solid black;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>