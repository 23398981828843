<template>
    <div>
        <h5 style="text-align: right; color: black;">
            <MDBBtn @click="collapse = !collapse" aria-controls="groupCollapse" tag="a" color="secondary">
                <MDBIcon :icon="collapse ? `arrow-down` : `arrow-up`"/>
            </MDBBtn>
            &#9;Groups
        </h5>
    </div>
    <div class="d-flex justify-content-end">
        <MDBCollapse v-model="collapse" id="groupCollapse" width="265px">
            <div
                    style="border: 1px solid black; overflow-x: auto; overflow-y: auto; height:300px; width: 255px">
                <p style="text-align: left; padding: 1%;">
                    <router-link :to="{name: 'CreateGroup'}">Create a New Group</router-link>
                </p>
                <div class="flex-nowrap" style="padding-bottom: 15px; justify-content: flex-end"
                >
                    <div class="card" v-for="group in groups" :key="group.idGroup"
                         style="width: 235px; margin-left: 10px; margin-right: 10px; height: 235px; padding: 0; margin-bottom: 2%;">
                        <div class="card-header">
                            <router-link :to="{name: 'ViewGroup', params: {idGroup: group.idGroup}}"
                                         style="font-weight: bold;">
                                {{ group.groupName }}
                            </router-link>
                            <!--           <router-link :to="{name: 'EditGroup', params: {idGroup: group.idGroup}}">Edit</router-link>-->
                            <b-tooltip target="_self" :title="`Delete ${group.groupName} group`">
                                <button class="bi bi-trash-fill" style="margin-left: 10px;"
                                        @click="deleteHelper(group.idGroup)"
                                        alt="Delete button"></button>
                            </b-tooltip>
                        </div>
                        <div class="card-body" style="overflow-y: scroll;">
                            <UserGroupDropdownDisplay
                                    :group-data="group"/>

                        </div>
                        <!--        <hr/>-->
                        <!--        <router-link :to="{name: 'EditGroup', params: {idGroup: group.idGroup}}">Edit</router-link>-->

                    </div>
                </div>
            </div>
        </MDBCollapse>
    </div>
</template>

<script>
import {ref, onBeforeMount} from "vue";
import axios from "axios";
import UserGroupDropdownDisplay from "@/views/SuperUserViews/UserGroups/UserGroupDropdownDisplay";
import {allGroupsByUser, deleteGroupById, removeUserFromGroup} from "@/views/SuperUserViews/UserGroups/GroupApiRoutes";
import {useStore} from "vuex";
import Swal from "sweetalert2";
import {MDBBtn, MDBCollapse, MDBIcon} from "mdb-vue-ui-kit";

export default {
	name: "UserGroupDisplayWrapper",
	components: {UserGroupDropdownDisplay, MDBCollapse, MDBIcon, MDBBtn},
	props: {},
	setup(props) {
		const store = useStore();
		const authToken = store.getters['auth/authenticated'];
		const groups = ref([]);
		const collapse = ref(false);

		onBeforeMount(() => {
			getGroups();
		})

		async function getGroups() {
			await axios.get(allGroupsByUser(), {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				groups.value = result.data;
			})

		}

		function deleteHelper(idGroup) {
			const alert_msg = `<p style="text-align: left;">You are about to delete this group. <span style="font-weight: bold;">This cannot be undone.</span></p>
                        <p style="text-align: left;">No user data will be deleted, but this specific group will not be available.</p>`
			Swal.fire({
				title: 'Are You Sure?',
				html: alert_msg,
				showCancelButton: true,
				confirmButtonText: "Okay",
				cancelButtonText: "Cancel"
			}).then((result) => {
				if (result.isConfirmed) {
					deleteGroup(idGroup);
				}
			})
		}

		async function deleteGroup(idGroup) {
			await axios.delete(deleteGroupById(idGroup), {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				if (result.status === 200) {
					Swal.fire({
						title: "Group deleted",
						confirmButtonText: "Okay"
					}).then(() => {
						getGroups()
					})
				}
			})
		}


		return {
			groups,
			collapse,
			deleteHelper,
		}
	}
}
</script>

<style scoped>
.card-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgb(0, 0, 0);
}

.card {
    border-radius: 0;

    border: 1px solid rgb(0, 0, 0);
}
</style>